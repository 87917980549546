import React from 'react'
import { NavLink } from 'react-router-dom'

import { Box, Icon, Typography } from '@pnld/components-web'

import { useSidebar } from '@/contexts/AppContext'

import S from '../../styles'
import { INavItemProps } from '../../types'

const NavItem = ({ onClose, name, icon, to, ...rest }: INavItemProps) => {
  const { isMini } = useSidebar()

  return (
    <S.NavItem
      as={NavLink}
      to={to}
      onClick={onClose}
      role="group"
      color="#23415B"
      _hover={{
        bg: '#D27723',
        color: 'white',
        borderBottomColor: '#E9DEB4',
      }}
      _activeLink={{
        color: '#D27723',
        bg: 'white',
        fontWeight: '700',
        borderBottomColor: '#D27723',
      }}
      borderBottomColor="#23415B"
      {...rest}
    >
      <Box mr={2}>
        <Icon name={icon} size="xs" inverse />
      </Box>
      <Typography
        variant="b-lg"
        _groupHover={{
          fontWeight: '600',
        }}
      >
        {!isMini ? name : ''}
      </Typography>
      {/* <S.NavItemName
        _groupHover={{
          fontWeight: '600',
        }}
      >
        {!isMini ? name : ''}
      </S.NavItemName> */}
    </S.NavItem>
  )
}

export default NavItem
