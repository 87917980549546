import React, { useMemo, useEffect, useContext } from 'react'

import { DrawerOverlay, useDisclosure, useMediaQuery } from '@chakra-ui/react'
import {
  Box,
  Flex,
  Drawer,
  DrawerContent,
  DrawerBody,
  useDrawer,
  DrawerProvider,
} from '@pnld/components-web'

import { useSidebar } from '@/contexts/AppContext'

import MobileNav from './parts/MobileNav'
import SidebarContent from './parts/SibebarContent'
import { ISidebarProps } from './types'

function Sidebar({ linkItems, children }: ISidebarProps) {
  // const { isOpen, onOpen, onClose } = useDisclosure()
  const { isMini, toggleSidebarState } = useSidebar()

  const [isMobile] = useMediaQuery('(max-width: 575px)')
  const sidebarWidth = useMemo(() => (isMini ? 12 : 48), [isMini])

  const { handleCloseDrawer, handleOpenDrawer } = useDrawer()

  useEffect(() => {
    if (isMini) {
      toggleSidebarState()
    }
  }, [isMobile])

  return (
    <DrawerProvider>
      <Box minHeight="100vh" bg="white">
        <SidebarContent
          display={{ base: 'none', md: 'flex' }}
          onClose={() => handleCloseDrawer}
          toggleSidebar={toggleSidebarState}
          sidebarWidth={sidebarWidth}
          linkItems={linkItems}
        />
        {/* <Drawer
        placement="left"
        returnFocusOnClose={false}
        onOverlayClick={handleCloseDrawer}
      /> */}
        <MobileNav
          display={{ base: 'flex', md: 'none' }}
          onOpen={() =>
            handleOpenDrawer(
              <DrawerContent>
                <DrawerBody>
                  <SidebarContent
                    onClose={handleCloseDrawer}
                    toggleSidebar={toggleSidebarState}
                    sidebarWidth={sidebarWidth}
                    linkItems={linkItems}
                  />
                </DrawerBody>
              </DrawerContent>
            )
          }
          sidebarWidth={sidebarWidth}
        />
        <Flex
          flexDirection="column"
          minHeight="100vh"
          ml={{ base: 0, md: sidebarWidth }}
          p={{ base: 4, md: 12 }}
          pt={{ base: 3, md: 6 }}
        >
          {children}
        </Flex>
      </Box>
    </DrawerProvider>
  )
}

export default Sidebar
