import React from 'react'
import ReactDOM from 'react-dom/client'

import { UiProvider } from '@pnld/components-web'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import App from './App'
import './assets/styles/globals.css'
import reportWebVitals from './reportWebVitals'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <QueryClientProvider client={queryClient}>
    <UiProvider>
      <App />
    </UiProvider>
  </QueryClientProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
