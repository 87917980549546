import React, { useState, useEffect } from 'react'
// import { BsCircleHalf } from 'react-icons/bs'
import { Navigate, Outlet } from 'react-router-dom'

// import { useColor } from 'utils/color'

import { Stack, Flex, useColorMode } from '@chakra-ui/react'

import { sidebarProps, useBreadcrumbProps } from '@/data/template'
import { stringToBoolean } from '@/utils/string'

import Breadcrumbs from './parts/Breadcrumbs'
import Sidebar from './parts/Sidebar'

function TemplatePage() {
  const [isAuthenticating, setIsAuthenticating] = useState(true)
  const [isAuthenticated, setIsAuthenticated] = useState(false)

  const { toggleColorMode } = useColorMode()
  const breadcrumbProps = useBreadcrumbProps()

  const darkModeButtonTextColor = 'red.600'
  const darkModeButtonBgColor = 'red.600'
  const darkModeButtonHoverBgColor = 'red.600'
  const darkModeButtonActiveBgColor = 'red.600'

  // useEffect(() => {
  //   async function checkAuthentication() {
  //     getAuthIsAuthenticated()
  //       .then(() => {
  //         setIsAuthenticated(true)
  //       })
  //       .catch(() => {
  //         setIsAuthenticated(false)
  //       })
  //       .finally(() => {
  //         setIsAuthenticating(false)
  //       })
  //   }

  //   if (!stringToBoolean(process.env.REACT_APP_DISABLE_AUTH)) {
  //     checkAuthentication()
  //   } else {
  //     setIsAuthenticated(true)
  //     setIsAuthenticating(false)
  //   }
  // }, [])

  // if (isAuthenticating) {
  //   return <LayoutLoading />
  // }

  // if (!isAuthenticated) {
  //   return <Navigate to="/login" />
  // }

  return (
    <Sidebar {...sidebarProps}>
      <Stack flexGrow={1} spacing="4">
        <Flex>
          <Breadcrumbs {...breadcrumbProps} />
        </Flex>
        <Outlet />
      </Stack>
    </Sidebar>
  )
}

export default TemplatePage
